// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/* global wpAjax */

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }
  $('.menu-icon').click(function () {
    $('.header').toggleClass('header-menu-active');
  });
  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  // hide form in fancybox

  setTimeout(function () {
    $('#popup-form').css({ display: 'none' });
  }, 1000);
  let headerHeight = $('#header').outerHeight();
  $('body').css('padding-top', headerHeight);
});

/**
 * Scripts which runs after all elements load
 */

$(window).on('load', function () {
  let headerHeight = $('#header').outerHeight();
  $('body').css('padding-top', headerHeight);
  // jQuery code goes here
  var menu_items_links = $('.header-menu li a');
  menu_items_links.each(function () {
    if ($(this).is('[href*="#"')) {
      $(this).parent().removeClass('current-menu-item current-menu-ancestor');
      $(this).click(function () {
        var current_index = $(this).parent().index(),
          parent_element = $(this).closest('ul');
        parent_element
          .find('li')
          .not(':eq(' + current_index + ')')
          .removeClass('current-menu-item current-menu-ancestor');
        $(this).parent().addClass('current-page-item current-menu-ancestor');
      });
      $(this).on('click', function () {
        $('.menu-icon').removeClass('is-active');
        $('.top-bar').css('display', 'none');
      });
    }
  });

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

// Biography Slider
var $biographySlider = $('.biography-slider').slick({
  arrows: true,
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: false,
  prevArrow: $('.bio-arrow__prev'),
  nextArrow: $('.bio-arrow__next'),
});

$('.variants-grid__wrapper, .variants-list__wrapper').click(function () {
  var artworkId = $(this).data('id');
  var artIndex = $biographySlider.find('[data-id="' + artworkId + '"]');
  var index = artIndex.parents('.slick-slide').data('slick-index');
  $biographySlider.slick('slickGoTo', index);
  $('html, body').animate({ scrollTop: 0 }, 'fast');
});

/**
 * Scripts Load More
 */

let ppp = 5;

function loadPosts() {
  let offset = $('.posts-wrapper').length;

  $.ajax({
    type: 'GET',
    dataType: 'json',
    url: wpAjax.ajaxurl,
    data: {
      action: 'loadmore_ajax',
      ppp: ppp,
      offset: offset,
    },
    success: function (response) {
      $('#elements').append(response.html);
      let count = $('.posts-wrapper').length >= response.post_count;
      if (count) {
        $('#loadmore').hide();
      }
    },
    error: function (result) {
      console.warn(result);
    },
  });
  return false;
}

$('#loadmore').on('click', function () {
  loadPosts();
});

$(function () {
  $('#close-button').click(function () {
    $.fancybox.close(true);
  });
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  let headerHeight = $('header').outerHeight();
  $('body').css('padding-top', headerHeight);
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
